import * as React from 'react'
import Announcement from 'react-announcement'
import Cat from "../images/cat.svg"
const announcement = require("../data/announcement.json")

class SiteAnnouncement extends React.Component {
  render () {
    return (
      <Announcement
          title={announcement.title}
          subtitle={announcement.subtitle}
          link={announcement.url}
          imageSource={Cat}
      />
    )
  }
}

export default SiteAnnouncement
