import React from "react"
import { Link } from "gatsby"
import Layout from "../../../components/layouts/main"
import SEO from "../../../components/seo"
import Helmet from "react-helmet"
import {PatreonButton, PatreonCorner} from "../../../components/patreon"
import Badge from "../../../components/badge"
import * as styles from "../../../components/styles/japanese.module.scss"
import SiteAnnouncement from "../../../components/announcement.js"

const IndexPage = () => (
  <Layout
    heading={(<div className={styles.banner}>
      <h1 className={styles.heading} style={{marginBottom: 0}}>Steven Kraft’s Japanese Projects</h1>
    </div>)}>
    <SEO title="Japanese Projects"
      description="Several interactive tools for learning Japanese grammar, vocab, kanji, and more! Including verb/adjective conjugation and Genki Textbook Practice."
      />
    <div>
      <h5 style={{textAlign:"center", fontSize:"26px"}}><strong style={{fontWeight:"600"}}>Check out the</strong> <Link style={{color:"var(--orange)"}} to="/projects/japanese/genki/"><strong style={{fontWeight:"600"}}>Supplementary Genki Practice content</strong></Link>!</h5>
    </div>
    <div className="row" style={{display:"flex"}}>
      <div className="column" style={{flex:"50%"}}>
        <h5>Verb Conjugation Practice</h5>
        <ul>
          <li><Link to="/projects/japanese/teform/">て-Form</Link></li>
          <li><Link to="/projects/japanese/causativeform/">Causative Form</Link></li>
          <li><Link to="/projects/japanese/conditionalform/">Conditional Form</Link></li>
          <li><Link to="/projects/japanese/imperativeform/">Imperative Form</Link></li>
          <li><Link to="/projects/japanese/negativeform/">Negative Form</Link></li>
          <li><Link to="/projects/japanese/passiveform/">Passive Form</Link></li>
          <li><Link to="/projects/japanese/pastform/">Past Form</Link></li>
          <li><Link to="/projects/japanese/politeform/">Polite Form</Link></li>
          <li><Link to="/projects/japanese/potentialform/">Potential Form</Link></li>
          <li><Link to="/projects/japanese/provisionalform/">Provisional Form</Link></li>
          <li><Link to="/projects/japanese/volitionalform/">Volitional Form</Link></li>
          <li><Link to="/projects/japanese/randomize/">Randomized Forms</Link></li>
        </ul>
      </div>
      <div className="column" style={{flex:"50%"}}>
        <h5>Adjective Conjugation Practice</h5>
        <ul>
          <li><Link to="/projects/japanese/adj-naruform/">なる Form</Link></li>
          <li><Link to="/projects/japanese/adj-conditionalform/">Conditional Form</Link></li>
          <li><Link to="/projects/japanese/adj-negativeform/">Negative Form</Link></li>
          <li><Link to="/projects/japanese/adj-pastform/">Past Form</Link></li>
          <li><Link to="/projects/japanese/adj-volitionalform/">Volitional Form</Link></li>
          <li><Link to="/projects/japanese/adj-randomize/">Randomized Forms</Link></li>
        </ul>
      </div>
    </div>

    <div className="row" style={{display:"flex"}}>
    <div className="column" style={{flex:"50%"}}>
      <h5>Numbers & Counters</h5>
      <ul>
        <li><Link to="/projects/japanese/counters/">Counters</Link></li>
        <li><Link to="/projects/japanese/days/">Days of the Month</Link></li>
        <li><Link to="/projects/japanese/numbers/">Numbers</Link></li>
        <li><Link to="/projects/japanese/time/">Time</Link></li>
      </ul>
    </div>
    <div className="column" style={{flex:"50%"}}>
      <h5>Kana & Kanji</h5>
      <ul>
        <li><Link to="/projects/japanese/kana/">Hiragana / Katakana</Link></li>
        <li><Link to="/projects/japanese/jlptn5">JLPT N5</Link></li>
      </ul>
    </div>
    </div>

    <h5>Other</h5>
    <ul>
      <li><Link to="#">JLPT N5 Vocab</Link> <Badge>Coming Soon</Badge></li>
      <li><Link to="/projects/japanese/transitive/">Transitive / Intransitive Verb Pairs</Link> <Badge>WIP</Badge></li>
      <li><Link to="/projects/japanese/transitive-or-intransitive/">Transitive / Intransitive Multiple Choice</Link></li>
      <li><Link to="/projects/japanese/na-vs-no/">な vs の Adjectives</Link></li>
      <li><Link to="/projects/japanese/cheatsheets/">Cheat Sheet Collection</Link></li>
    </ul>

    <p style={{textAlign:"center"}}>Thank you to all of my <Link style={{fontWeight: 400}} to="/projects/japanese/patreon">Patreon Supporters</Link> for helping me keep this website running!</p>
    <p style={{margin:"auto", display:"table"}} ><PatreonButton /></p>
    <PatreonCorner />
    <SiteAnnouncement />
  </Layout>
)

export default IndexPage
